const FULL_SCREEN_BREAKPOINT = 767;

const classes = {
  mainWindowContainer: 'body',
  fixedBody: 'body--fixed'
};

export const updateWindowContainer = (status: boolean): void => {
  if (status && window.innerWidth <= FULL_SCREEN_BREAKPOINT) {
    const mainWindowContainer = document.querySelector(
      classes.mainWindowContainer
    ) as HTMLElement | null;
    if (mainWindowContainer) {
      setTimeout(() => {
        mainWindowContainer.classList.add(classes.fixedBody);
      }, 350);
    }
  } else {
    const mainWindowContainer = document.querySelector(
      classes.mainWindowContainer
    ) as HTMLElement | null;
    if (mainWindowContainer) {
      mainWindowContainer.classList.remove(classes.fixedBody);
    }
  }
};

export const scrollToElementBottom = (element: any): void => {
  if (element) {
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }
};

export const getWindowWidth = (): number => {
  return document.body.clientWidth || document.documentElement.clientWidth;
};
