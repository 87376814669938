import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import "./chat-tray.css";
import {
  toggleChatTray,
  renderHtmlInTray,
  updateTrayExpandListIndex,
} from "../../redux/chatbotSlice";

const ChatTrayHeader: React.FC = () => {
  const { isChatTrayOpen, isRenderHtmlInTray } = useAppSelector(
    (state) => state.chatbot
  );
  const dispatch = useAppDispatch();

  const handleChatTrayCloseAction = () => {
    if (isChatTrayOpen === true) {
      dispatch(toggleChatTray(false));
      dispatch(renderHtmlInTray({isRenderHtmlInTray: false,}));
      dispatch(updateTrayExpandListIndex(-1));
    }
  };

  const handleChatTrayHomeAction = () => {
    if (isRenderHtmlInTray === true) {
      dispatch(renderHtmlInTray({isRenderHtmlInTray: false,}));
    }
    dispatch(updateTrayExpandListIndex(-1));
  };

  const renderChatTrayCloseIcon = () => {
    return (
      <div onClick={handleChatTrayCloseAction} className="chat-tray-close">
        {renderChatTrayCloseIconOnMobile()}
      </div>
    );
  };

  const renderChatTrayCloseIconOnMobile = () => {
    return (
      <p className="chat-tray-close-mobile">
        <svg
          focusable="false"
          aria-hidden="true"
          className="icon icon--small chat-tray-close-icon-mobile"
        >
          <use xlinkHref="#icon-chevron-left" />
        </svg>
        Back to chat
      </p>
    );
  };

  const renderChatTrayHomeIcon = () => {
    if (isRenderHtmlInTray) {
      return (
        <div onClick={handleChatTrayHomeAction} className="chat-tray-home">
          Back to all Terms & Conditions &gt;
        </div>
      );
    }
  };

  const onWindowResize = () => {
    renderChatTrayCloseIcon();
  };
  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  });

  return (
    <div
      className={`chat-tray-header ${
        isChatTrayOpen ? "" : "chat-tray-close-header"
      }`}
    >
      {renderChatTrayCloseIcon()}
      {renderChatTrayHomeIcon()}
    </div>
  );
};

export default ChatTrayHeader;
