import React, {ReactNode} from "react";

interface BubbleWrapperProps {
  wrapperClass: string;
  children: ReactNode;
}

const BubbleWrapper: React.FC<BubbleWrapperProps> = ({
  wrapperClass,
  children,
}) => {
  return (
    <div className={`chat-bubble-wrapper ${wrapperClass}`}>
      <div className="chat-message-text">{children}</div>
    </div>
  );
};

export default BubbleWrapper;
