import React, { useRef, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import "./chat-tray.css";
import ChatTrayContentList from "./ChatTrayContentList";
import ChatTrayIframe from "./ChatTrayIframe";
import { Scrollbars } from "react-custom-scrollbars";

const ChatTrayBody: React.FC = () => {
  const {
    trayEmbedHtmlUrl,
    isRenderHtmlInTray,
    isChatTrayOpen,
    trayExpandListIndex,
  } = useAppSelector((state) => state.chatbot);
  const trayScrollBarRef = useRef<Scrollbars | null>(null);

  const renderTrayContent = () => {
    if (!isRenderHtmlInTray && isChatTrayOpen && trayExpandListIndex !== -2) {
      return <ChatTrayContentList />;
    }
  };

  useEffect(() => {
    if (!isRenderHtmlInTray) {
      const updateScrollBar = () => {
        if (trayScrollBarRef.current) {
          trayScrollBarRef.current.scrollToBottom();
        }
      };
      setTimeout(updateScrollBar, 600);
    }
  }, [isRenderHtmlInTray]);

  const renderEmbedHtml = (link: string) => {
    if (isRenderHtmlInTray && isChatTrayOpen) {
      return <ChatTrayIframe webpageUrl={link} />;
    }
  };

  return (
    <div
      className={`chat-tray-body ${isRenderHtmlInTray ? "html-in-tray" : ""}${
        isChatTrayOpen ? "" : "chat-tray-close-footer"
      }`}
    >
      <Scrollbars
        className="tray-scroll-bar"
        renderTrackHorizontal={(props) => (
          <div {...props} className="horizontal-scrollbar" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="vertical-scrollbar" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="vertical-scrollbar-inner" />
        )}
        renderView={(props) => <div {...props} className="scroll-view" />}
        ref={trayScrollBarRef}
      >
        <div className="tray-content-container">
          {renderTrayContent()}
          {renderEmbedHtml(trayEmbedHtmlUrl)}
        </div>
      </Scrollbars>
    </div>
  );
};

export default ChatTrayBody;
