import React, { Fragment, useEffect, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import "./chat-tray.css";
import { renderHtmlInTray } from "../../redux/chatbotSlice";
import { SmallSpinner } from "../spinner/Spinner";

type Props = {
  webpageUrl: string;
};

const ChatTrayIframe: React.FC<Props> = ({ webpageUrl }: Props) => {
  const { trayEmbedHtmlUrl, trayIframeLoaded } = useAppSelector(
    (state) => state.chatbot
  );
  const dispatch = useAppDispatch();

  const updateIframeLoadStatus = useCallback(() => {
    let trayIframe = document.querySelector(
      "#tray-iframe"
    ) as HTMLIFrameElement;
    const regExp = new RegExp("one.nz", "g");
    if (trayIframe) {
      trayIframe.onload = () => {
        dispatch(renderHtmlInTray({ trayIframeLoaded: false }));
        if (trayIframe.contentWindow && trayEmbedHtmlUrl.match(regExp)) {
          const chatSection = trayIframe.contentWindow.document.querySelector(
            ".chat-bot-container"
          ) as HTMLElement;
          if (chatSection) {
            chatSection.style.display = "none";
          }
        }
      };
    }
  }, [dispatch, trayEmbedHtmlUrl]);

  useEffect(() => {
    updateIframeLoadStatus();
    return () => {
      dispatch(renderHtmlInTray({ trayIframeLoaded: false }));
    };
  }, [webpageUrl, dispatch, updateIframeLoadStatus]);

  const renderLoading = () => {
    return (
      <div className="tray-spinner">
        <SmallSpinner />
      </div>
    );
  };

  return (
    <Fragment>
      <div className={`${trayIframeLoaded ? "hide-spinner" : ""}`}>
        {renderLoading()}
      </div>
      <iframe
        id="tray-iframe"
        title="ChatTrayIframe"
        className={`tray-embed-html ${trayIframeLoaded ? "" : "hide-iframe"}`}
        src={webpageUrl}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin"
      ></iframe>
    </Fragment>
  );
};

export default ChatTrayIframe;
