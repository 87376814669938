/* eslint-disable */
import config from "./assistConfig";
// jshint esversion: 6

const THRESHOLD = 0.6;
// if there are more than 2 words, more than half of the
// words that are not ignored, must match
const TWO_WORD_THRESHOLD = 0.5;
// if there are only two words given, then
// they must match at least this percentage of
// the phrase. 0.35 means more than two in six

// Replace find and some methods available in ES6. These methods are not supported by IE-11

// The find() method returns the value of the first element in an array that pass a test (provided
// as a function).
//
// The find() method executes the function once for each element present in the array:
//
// If it finds an array element where the function returns a true value, find() returns the value of
// that array element (and does not check the remaining values)
// Otherwise it returns undefined
// Note: find() does not execute the function for empty arrays.
//
// Note: find() does not change the original array.
//
// Evaluator function definition = function(currentValue, index, arr)
Array.prototype.find = function (evaluator: any) {
  try {
    this.forEach(function (item, index) {
      // @ts-ignore
      if (evaluator(item, index, this)) {
        throw item;
      }
    });
  } catch (e) {
    return e;
  }
  return undefined;
};

// The some() method checks if any of the elements in an array pass a test (provided as a function).
//
// The some() method executes the function once for each element present in the array:
//
// If it finds an array element where the function returns a true value, some() returns true (and
// does not check the remaining values)
// Otherwise it returns false
//
// Note: some() does not execute the function for array elements without values.
//
// Note: some() does not change the original array.
//
// Evaluator function definition = function(currentValue, index, arr)
// @ts-ignore
Array.prototype.some = function (evaluator) {
  try {
    this.forEach(function (item, index) {
      // @ts-ignore
      if (evaluator(item, index, this)) {
        throw true;
      }
    });
  } catch (e) {
    return e;
  }
  return false;
};

const gatherPhrases = (text: string) => {
  let phrases: any = [];
  // process phrases
  config.alt_phrases.forEach(function (item) {
    let pattern = new RegExp("\\b" + item + "\\b", "i");
    if (text.match(pattern)) {
      // add phrase to array of matched phrases
      if (!shouldIgnore(item, config.alt_ignore)) phrases.push(synonyms(item));
      text = text.replace(pattern, ""); // remove the phrase
    }
  });
  return [phrases, text];
};

const gatherWords = (text: string) => {
  let words: any = [];

  let parts = text.match(/\b(\S+)\b/g); // split the words in the text
  if (parts !== null) {
    parts.forEach(function (word) {
      // add word or synonyms to list of words for matching
      if (!shouldIgnore(word, config.alt_ignore)) words.push(synonyms(word));
    });
  }
  return words;
};

const count_matches = (text: any, phrases: any, words: any) => {
  let count_phrases = 0;
  let count_words = 0;

  let textTemp = text; //.repeat(1);
  let regE; // need regular expression for case-insensitivity
  let matches = null;

  // count phrases
  if (phrases.length) {
    let pattern_p = "\\b(" + patternFor(phrases) + ")\\b";
    regE = new RegExp(pattern_p, "ig");
    matches = text.match(regE);
    if (matches != null) {
      count_phrases = matches.length;
      textTemp = text.replace(regE, ""); // remove phrases from text
    }
  }
  // count remaining words
  if (words.length) {
    let pattern_w = "\\b(" + patternFor(words) + ")\\b";
    let isValid = true;
    try {
      regE = new RegExp(pattern_w, "ig");
    } catch (e) {
      isValid = false;
    }

    if (isValid) {
      matches = textTemp.match(regE);
    }

    if (matches != null) count_words = matches.length;
  }
  return count_phrases + count_words;
};

// this version weights the responses probablistically
export const suggestionList = (text: any) => {
  let words_u: any = []; // words in the utterance that are not ignored
  let phrases_u: any = []; // detected phrases in the utterance that are not ignored
  let words_e = []; // words in an example that are not ignored for part of phrases
  let phrases_e = []; // phrases in an example

  let scores: any = [];
  let list: any = [];

  // gather phrases from the utterance - text is updated to remove phrases
  phrases_u = gatherPhrases(text); // returns [phrases[], text]
  text = phrases_u[1];
  phrases_u = phrases_u[0];

  // process remaining words
  words_u = gatherWords(text);

  // step through examples
  config.texts.forEach(function (item) {
    let example = item; // .repeat(1); // get a copy of the example
    phrases_e = gatherPhrases(example); // returns [phrases[], text]
    example = phrases_e[1];
    phrases_e = phrases_e[0];

    words_e = gatherWords(example);

    let cnt = count_matches(item, phrases_u, words_u);

    let score =
      (2.0 * cnt) /
      (phrases_e.length + words_e.length + phrases_u.length + words_u.length);

    // add to list if greater than percentage threshold or at least X words/phrases match
    if (
      score > THRESHOLD ||
      (phrases_u.length + words_u.length <= 2 && score >= TWO_WORD_THRESHOLD)
    ) {
      list.push([item, Math.round(score * 100) / 100]);
      scores.push(score);
    }
  });

  // now sort them
  // temporary array holds objects with position and sort-value (score)
  let mapped = scores.map(function (el: any, i: any) {
    return { index: i, value: el };
  });

  // sorting the mapped array containing the reduced values
  mapped.sort(function (a: any, b: any) {
    if (a.value < b.value) {
      return -1; // in descending order
    }
    if (a.value > b.value) {
      return 1; // in ascending order
    }
    return 0;
  });

  // container for the resulting order
  let result = mapped.map(function (el: any) {
    return list[el.index];
  });
  list = result;

  return list;
};

// return either a word or an array of words
// iterate through synonyms - return a synonym item that includes the word
// synonym may be symetrical [a,b,c]  a => [a,b,c]
// or asymetrical [[a,b],[c,d]] a => [a,c,d] or even c => [c,a,b]
// items may be regexes, e.g. calls? or info(rmation)?
const synonyms = (word: any) => {
  let rval: any = [word];
  let matched_word: any = "";

  rval = config.synonyms.find(function (item) {
    if (Array.isArray(item[0])) {
      // asymetrical pair
      return item[0].some(function (item) {
        matched_word = item;
        return new RegExp("\\b" + item + "\\b", "i").test(word);
      });
    } else {
      // symetrical
      return item.some(function (item) {
        matched_word = item;
        return new RegExp("^" + item + "$", "i").test(word);
      });
    }
  });
  if (!rval) rval = word;
  else if (Array.isArray(rval[0])) {
    rval = [matched_word].concat(rval[1]);
  }
  return rval;
};

const patternFor = (phrasesOrWords: any) => {
  let pattern = "";

  if (!phrasesOrWords.length) return pattern;

  phrasesOrWords.forEach(function (item: any, index: any) {
    if (index != 0) pattern += "|";
    if (Array.isArray(item)) {
      // synonyms
      pattern += "(" + item.join("|") + ")";
    } else {
      // simple string
      pattern += item;
    }
  });
  return pattern;
};

const shouldIgnore = (word: any, ignore_list: any) => {
  return ignore_list.some(function (item: any) {
    return new RegExp("^" + item + "$", "i").test(word);
  });
};
