const userAgent = window.navigator.userAgent;

export const isOnIos = () => {
    return /VFNZiOSApp/i.test(userAgent);
};

export const isOnAndroid = () => {
    return /VFNZAndroidApp/i.test(userAgent);
};

export const isOnReactIos = () => {
    return /VFNZReactIosApp/i.test(userAgent);
};

export const isOnReactAndroid = () => {
    return /VFNZReactAndroidApp/i.test(userAgent);
};

export const isMyVFApp = () => {
    return isOnReactIos() || isOnReactAndroid() || isOnAndroid();
};

export const isOnIphone = () => {
    return /iphone/i.test(userAgent);
};

export const isAndroidDevice = () => {
    return /android/i.test(userAgent);
};

export const isOnIpad = () => {
    return /ipad/i.test(userAgent);
};

export const isOnIosDevice = () => {
    return isOnIphone() || isOnIpad();
};

export const isOldIEBrowser = () => {
    // Internet Explorer 6-10
    return /compatible/i.test(userAgent) && /MSIE/i.test(userAgent);
};

export const isIE11Browser = () => {
    // Internet Explorer 11
    return /Trident/i.test(userAgent) && /rv:11.0/i.test(userAgent);
};

export const isIEEdgeBrowser = () => {
    return /Edg/i.test(userAgent);
};

export const isSafariBrowser = () => {
    return /Safari/i.test(userAgent) && !/Chrome/i.test(userAgent);
};

export const isChromeBrowser = () => {
    return /Chrome/i.test(userAgent) && /Safari/i.test(userAgent);
};

export const isFirefoxBrowser = () => {
    return /Firefox/i.test(userAgent);
};

export const isOperaBrowser = () => {
    return /Opera/i.test(userAgent);
};

export const isOnMacOs = () => {
    return /macintosh|mac os x/i.test(userAgent);
};

export const isOnLinux = () => {
    return /linux/i.test(userAgent);
};

export const isOnWindows = () => {
    return /Windows/i.test(userAgent);
};

export const isOnWindowsMobile = () => {
    return /Windows Phone/i.test(userAgent);
};

export const getPlatform = () => {
    if (isMyVFApp()) {
        return 'App';
    } else if (isAndroidDevice()) {
        return 'Android';
    } else if (isOnIosDevice()) {
        return 'iOS';
    } else if (isOnMacOs()) {
        return 'Mac OSX';
    } else if (isOnWindows()) {
        return 'Windows Desktop';
    } else if (isOnLinux()) {
        return 'Linux';
    } else {
        return '';
    }
};

export const getBrowserType = () => {
    if (isOldIEBrowser()) {
        return 'IE Old';
    } else if (isIE11Browser()) {
        return 'IE 11';
    } else if (isIEEdgeBrowser()) {
        return 'IE Edge';
    } else if (isSafariBrowser()) {
        return 'Safari';
    } else if (isChromeBrowser()) {
        return 'Chrome';
    } else if (isFirefoxBrowser()) {
        return 'Firefox';
    } else if (isOperaBrowser()) {
        return 'Opera';
    } else {
        return '';
    }
};

export const isMobileDevice = () => {
    return isAndroidDevice() || isOnIosDevice() || /webOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

export const isOnMobile = () => {
    return isMobileDevice() || isMyVFApp();
};

