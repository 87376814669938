import React, { useEffect, Fragment, useRef, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  sendLexMessage,
  restartLexChatInstance,
  lexSessionValue,
} from "../services/socketClient";
import { scrollToElementBottom } from "../helper/resizeEvents";
import {
  speedTestCompleted,
  speedTestResultListener,
} from "../helper/speedTestEvents";
import BubbleWrapper from "./BubbleWrapper";
import { Scrollbars } from "react-custom-scrollbars";
import {
  toggleChatTray,
  renderHtmlInTray,
  updateTrayExpandListIndex,
  ChatMessageType,
  QuickReplyType,
} from "../redux/chatbotSlice";
import { isOnMobile } from "../helper/BrowserHelper";
import { hasSpecialTags } from "../helper/responseChecker";
import Thumbs from "./views/Thumbs";

const ChatBody: React.FC = () => {
  const {
    isInChatQueue,
    isConnectToAgent,
    isChatTrayOpen,
    chatMessage,
    quickReply,
    isBotTyping,
    awsConnect,
  } = useAppSelector((state) => state.chatbot);
  const scrollBarRef = useRef<Scrollbars | null>(null);
  const mainElRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const onWindowResize = () => {
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollToBottom();
    }
    scrollToElementBottom(mainElRef);
  };
  
  const noticeUserLeft = useCallback(() => {
    let connectionToken =
      lexSessionValue().controller.connectionHelper.getConnectionToken();
    let endpoint = `${awsConnect.zeroRatedPoint}/participant/disconnect`;
    let requestPayload = {
      ConnectionToken: connectionToken,
    };

    let http = new XMLHttpRequest();
    http.open("POST", endpoint, false);
    http.setRequestHeader("x-amz-bearer", connectionToken);
    http.setRequestHeader("Content-Type", "application/json");
    http.send(JSON.stringify(requestPayload));
  },[awsConnect.zeroRatedPoint]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
    speedTestResultListener(speedTestCompleted);

    // notice agent use abandoned the chat
    const beforeUnloadHandler = () => {
      if (isInChatQueue || isConnectToAgent) {
        noticeUserLeft();
      }
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isConnectToAgent, isInChatQueue, noticeUserLeft]);

  useEffect(() => {
    onWindowResize();
    updateAllLinksOpenInTray();

    // auto focus only on desktop
    if (chatMessage.length > 1 && !isOnMobile()) {
      const chatInput = document.querySelector(
        ".chat-footer .chat-input"
      ) as HTMLInputElement;
      if (chatInput) {
        chatInput.focus();
      }
    }
  });


  const updateAllLinksOpenInTray = () => {
    let links = document.querySelectorAll(".chat-body a");

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", (event) => {
        links[i].classList.add("visited");
        if (links[i].hasAttribute("nottray")) return;
        if (links[i].getAttribute("opentrayindex")) {
          let e = event;
          e.preventDefault();
          const index = parseInt(
            links[i].getAttribute("opentrayindex") || "0",
            10
          );
          if (isChatTrayOpen === false) {
            dispatch(toggleChatTray(true));
          }
          dispatch(renderHtmlInTray({ isRenderHtmlInTray: false }));
          dispatch(updateTrayExpandListIndex(index - 1));
        } else if (
          (links[i] as HTMLAnchorElement).href &&
          /^https/.test((links[i] as HTMLAnchorElement).href)
        ) {
          let e = event;
          e.preventDefault();
          if (isChatTrayOpen === false) {
            dispatch(toggleChatTray(true));
          }
          dispatch(
            renderHtmlInTray({
              isRenderHtmlInTray: true,
              trayEmbedHtmlUrl: (links[i] as HTMLAnchorElement).href,
              trayIframeLoaded: false,
            })
          );
        }
      });
    }
  };

  const handleQuickReplyAction =
    (action = "default") =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (action === "restart") {
        const chatBody = document.querySelector<HTMLDivElement>(".chat-body");
        if (chatBody) {
          chatBody.removeAttribute("style");
        }
        restartLexChatInstance();
      } else {
        sendLexMessage("user", event.currentTarget.value);
      }
    };

  const renderTyping = (isBotTyping: boolean) => {
    if (!isBotTyping) {
      return;
    }

    const sender = isConnectToAgent ? "livechat" : "bot";
    return (
      <div className={`chat-message-wrapper-${sender}`}>
        <div className={`${sender}-avatar-wrapper`}>
          {sender === "bot" ? renderBotImage() : renderLiveChatImage()}
        </div>
        <div className="chat-bubble-typing-wrapper">
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  };

  const renderQuickReplys = (quickReplyMessage: QuickReplyType[]) => {
    return (
      <div className="quick-reply-wrapper">
        {quickReplyMessage.map(({ payload, title, istnps, action }, i) => [
          <button
            key={i}
            className={`quick-reply ${istnps === "true" ? "tnps-button" : ""}`}
            value={payload}
            onClick={handleQuickReplyAction(action)}
            type="button"
          >
            {title}
          </button>,
        ])}
      </div>
    );
  };

  const renderBotWrapperImage = (sender: string, isGroupMsg: boolean) => {
    if (sender !== "bot" && sender !== "livechat") {
      return "";
    }

    return (
      <div
        className={`${sender}-avatar-wrapper ${isGroupMsg ? "groupmsg" : ""}`}
      >
        {sender === "bot" ? renderBotImage() : renderLiveChatImage()}
      </div>
    );
  };

  const renderBotImage = () => {
    return <p className="small-hana-icon" />;
  };

  const renderLiveChatImage = () => {
    return <p className="live-chat-icon" />;
  };

  const renderMessages = (message: any) => {
    if (!message.text) {
      return "";
    }

    if (hasSpecialTags(message.text) === "thumbs") {
      return <Thumbs message={message.text} />;
    }

    return (
      <BubbleWrapper wrapperClass="">
        <span dangerouslySetInnerHTML={{ __html: message.text }} />
      </BubbleWrapper>
    );
  };

  const removeEmptyMessage = (chatMessages: ChatMessageType[]) => {
    chatMessages.forEach((chatMessage, index, object) => {
      if (chatMessage.message.text === "") {
        object.splice(index, 1);
      }
    });
    return chatMessages;
  };

  return (
    <div className="chat-body">
      <Scrollbars
        key="scrollBar"
        className="chat-scroll-bar"
        renderTrackHorizontal={(props) => (
          <div {...props} className="horizontal-scrollbar" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="vertical-scrollbar" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="vertical-scrollbar-inner" />
        )}
        renderView={(props) => <div {...props} className="scroll-view" />}
        ref={scrollBarRef}
      >
        <div className="conversation-body-container" ref={mainElRef}>
          <div className="conversation-wrapper">
            <div className="conversation-list">
              {removeEmptyMessage(chatMessage).map(
                ({ sender, message, chatId, isGroupMsg }, i) => [
                  <Fragment key={chatId}>
                    <div
                      className={`chat-message-wrapper-${sender}${
                        chatMessage.length - 1 === i ? " last-comment" : ""
                      }`}
                    >
                      {renderBotWrapperImage(sender, isGroupMsg)}
                      {renderMessages(message)}
                    </div>
                  </Fragment>,
                ]
              )}
              {renderTyping(isBotTyping)}
            </div>
          </div>
          <div className="quick-action-wrapper">
            {renderQuickReplys(quickReply)}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default ChatBody;
