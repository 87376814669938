const cmsConfig = {
  vfoMultiSearchInputHint: ["Ask me a question", "How can I help?"],
  vfoHeadline: "<strong>Hana </strong> - Digital Assistant",
  vfoBotRestartChatLabel: "Start again",
  vfoSplashScreenHeadline:
    "Kia Ora. I'm <strong>Hana,</strong><br> One NZ's chatbot",
  vfoWebServiceErrors: [
    {
      id: 1575614769750,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "restart",
      vfoWebServiceErrorCode: 500,
      name: "Restart_Conversation",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "restart",
      vfoErrorMessageText: "Reset <time> - Let's start again",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
    {
      id: 1542069189547,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "inactivity",
      vfoWebServiceErrorCode: 500,
      name: "User_Inactivity",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "inactivity",
      vfoErrorMessageText:
        "Chat closed due to inactivity. Thank you for chatting with me.",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
    {
      id: 1542069341545,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "default",
      vfoWebServiceErrorCode: 500,
      name: "Default_BOT_ERROR",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "default",
      vfoErrorMessageText: "Thank you for chatting with me",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
  ],
  urgentInfo: "",
  welcomeMessages: [
    "Kia ora, I'm Hana",
    "Type <strong>human</strong> at any time if you prefer a real person",
  ],
  salesWelcomeMessages: ["Kia ora, I'm Hana"],
};

export default cmsConfig;
