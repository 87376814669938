import React, { useEffect, useCallback } from "react";
import "./chat-tray.css";
import { useAppDispatch } from "../../redux/hooks";
import { sendLexMessage } from "../../services/socketClient";
import { toggleChatTray, renderHtmlInTray } from "../../redux/chatbotSlice";
import { getWindowWidth } from "../../helper/resizeEvents";

type Props = {
  data: any;
  expandIndex: number;
  currentIndex: number;
  onExpand: (index: number) => void;
};

const ChatTrayContentListItem: React.FC<Props> = ({
  data,
  expandIndex,
  currentIndex,
  onExpand,
}: Props) => {
  const BREAK_POINT = 767;
  const dispatch = useAppDispatch();

  const addClickEventToMenuLinks = useCallback(() => {
    let links = document.querySelectorAll(
      ".tray-content-container .tray-question-link"
    );
    for (let i = 0; i < links.length; i++) {
      const link = links[i] as HTMLAnchorElement;
      link.addEventListener("click", () => {
        if (getWindowWidth() <= BREAK_POINT) {
          dispatch(toggleChatTray(false));
        }
        sendLexMessage("user", `${link.title} - ${link.innerHTML}`);
      });
    }
  }, [dispatch]);

  const updateLinksToOpenInTray = useCallback(() => {
    let links = document.querySelectorAll(
      ".tray-content-container .open-in-tray"
    );
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", (event) => {
        if ((links[i] as HTMLAnchorElement).href) {
          let e = event;
          e.preventDefault();
          dispatch(
            renderHtmlInTray({
              isRenderHtmlInTray: true,
              trayEmbedHtmlUrl: (links[i] as HTMLAnchorElement).href,
              trayIframeLoaded: false,
            })
          );
        }
      });
    }
  }, [dispatch]);

  const onHandleExpand = () => {
    if (currentIndex === expandIndex) {
      onExpand && onExpand(-1);
    } else {
      onExpand && onExpand(currentIndex);
    }
  };

  useEffect(() => {
    addClickEventToMenuLinks();
    updateLinksToOpenInTray();
  }, [addClickEventToMenuLinks, updateLinksToOpenInTray]);

  const renderListTitle = () => {
    return (
      <div className="list-title" onClick={onHandleExpand}>
        <span className="list-title-detail">{data.title}</span>
        <span className="list-title-chevron">
          <svg
            className={`${
              expandIndex === currentIndex ? "hide" : "show"
            } icon icon--extra-small chevron__icon chevron__icon--grey`}
          >
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#icon-chevron-down"
            />
          </svg>
          <svg
            className={`${
              expandIndex === currentIndex ? "show" : "hide"
            } icon icon--extra-small chevron__icon chevron__icon--grey`}
          >
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#icon-chevron-up"
            />
          </svg>
        </span>
      </div>
    );
  };

  return (
    <div
      key={data.title}
      className={`content-group ${
        expandIndex === currentIndex ? "expanded" : ""
      }`}
    >
      {renderListTitle()}
      <div className="list-detail">
        <p dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
    </div>
  );
};

export default ChatTrayContentListItem;
