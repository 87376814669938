import { sendLexMessage } from '../services/socketClient';
import commonConstants from './constant';

export const speedTestResultListener = (listener: (event: MessageEvent) => void): void => {
  if (window.addEventListener) {
    window.addEventListener('message', listener);
  }
};

export const speedTestCompleted = (event: MessageEvent): void => {
  const speedLimit = 25;
  if (event.data.download) {
    let download = event.data.download / 1000;
    download >= speedLimit
      ? sendLexMessage('user', commonConstants.utterance.above25Mbps, false)
      : sendLexMessage('user', commonConstants.utterance.below25Mbps, false);
  }
};
