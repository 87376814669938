import React from "react";

const ChatSvgs: React.FC = () => {
  return (
    <div className="svgs">
      <svg>
        <defs>
          <symbol viewBox="0 0 106 144" id="icon-play">
            <title>main_icn_Play_arrow</title>
            <path
              d="M52,37.71V153.79A10.08,10.08,0,0,0,61.51,164a9.82,9.82,0,0,0,6.31-2l1.39-1,74.26-55.21,2.19-1.64a9.67,9.67,0,0,0-.07-16.19l-2.11-1.59-74-55.05c-3.08-2.53-6.91-4.38-11.37-2.42A10.55,10.55,0,0,0,52,38.55v.78"
              transform="translate(-48 -24)"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </symbol>

          <symbol viewBox="0 0 144 76" id="icon-chevron-down">
            <title>main_icn_Chevron_down</title>
            <polyline
              id="chevron-down-00134c7b-b10c-486b-af1b-ea0801b1e1c2"
              data-name="<Pfad>"
              points="140 4 72 72 4 4"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></polyline>
          </symbol>

          <symbol viewBox="0 0 76 144" id="icon-chevron-right">
            <title>main_icn_Chevron_right</title>
            <polyline
              id="chevron-right-2063ebf3-901a-4482-a254-6509fb6140e6"
              data-name="<Pfad>"
              points="4 4 72 72 4 140"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></polyline>
          </symbol>
          <symbol viewBox="0 0 76 144" id="icon-chevron-left">
            <title>main_icn_Chevron_left</title>
            <polyline
              id="chevron-left-a4e0e987-f44d-495f-8041-cbcac2454416"
              data-name="<Pfad>"
              points="72 140 4 72 72 4"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></polyline>
          </symbol>
          <symbol viewBox="0 0 144 76" id="icon-chevron-up">
            <title>main_icn_Chevron_up</title>
            <g
              id="chevron-up-4a7f26dd-de76-4f24-b685-2fa7606c1b95"
              data-name="Chevron_up"
            >
              <g
                id="chevron-up-27178c23-1a1e-4b8d-8697-779c9c91248e"
                data-name="Chevron_down"
              >
                <polyline
                  id="chevron-up-b8bd3b47-86e3-4bb6-af24-009884759286"
                  data-name="<Pfad>"
                  points="4 72 72 4 140 72"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </g>
            </g>
          </symbol>
          <symbol viewBox="0 0 144 144" id="icon-close" strokeWidth="10">
            <title>main_icn_Close</title>
            <line
              id="close-2b810136-93de-4622-a0cc-fbdf15eb7667"
              data-name="
              <Pfad>"
              x1="4"
              y1="4"
              x2="140"
              y2="140"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              id="close-2d18ff19-f34e-4c5d-9791-2549bee849dd"
              data-name="
                <Pfad>"
              x1="4"
              y1="140"
              x2="140"
              y2="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
          </symbol>
        </defs>
      </svg>
      <svg
        id="icon-tobi-thumbs-down"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.44141 0.799988H14.2177C15.4282 0.799988 16.4282 1.74078 15.8855 3.05657C17.1223 4.4842 16.471 5.56315 16.471 5.56315C16.471 5.56315 17.6421 6.83288 16.5039 8.06644C16.8 8.89209 16.9677 9.62565 15.8921 10.3559C14.8131 11.0862 12.2506 10.3789 11.3131 10.2605C10.0631 10.6585 13.7967 16.6947 9.82299 16.8C9.80654 16.7572 9.35917 16.9053 9.48417 15.3395C9.48417 13.7046 6.40193 10.0631 5.39206 8.64867"
          stroke="#5A6376"
          strokeWidth="1.5"
          strokeLinejoin="round"
        ></path>
        <path
          d="M6.05068 0.799986H3.10331C1.94213 0.799986 0.998047 1.74407 0.998047 2.90525L0.998047 7.11578C0.998047 8.27696 1.94213 9.22104 3.10331 9.22104H3.94542C5.1066 9.22104 6.05068 8.27696 6.05068 7.11578L6.05068 0.799986Z"
          stroke="#5A6376"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
      <svg
        id="icon-tobi-thumbs-up"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.44336 16.798H14.217C15.4272 16.798 16.4269 15.8574 15.8843 14.542C17.1208 13.1147 16.4697 12.036 16.4697 12.036C16.4697 12.036 17.6404 10.7666 16.5026 9.53335C16.7985 8.7079 16.9663 7.97453 15.8909 7.24445C14.8122 6.51438 12.2503 7.22143 11.3131 7.33983C10.0634 6.9419 13.796 0.907239 9.82333 0.802002C9.80688 0.844754 9.35963 0.696765 9.4846 2.26216C9.4846 3.89662 6.40314 7.53714 5.39353 8.95126"
          stroke="#5A6376"
          strokeWidth="1.5"
          strokeLinejoin="round"
        ></path>
        <path
          d="M6.05136 16.798H3.10473C1.94384 16.798 1 15.8541 1 14.6932V10.4838C1 9.32287 1.94384 8.37903 3.10473 8.37903H3.94662C5.10752 8.37903 6.05136 9.32287 6.05136 10.4838V16.798Z"
          stroke="#5A6376"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  );
};

export default ChatSvgs;
