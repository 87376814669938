import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch } from "../../redux/hooks";
import "./chat-assist.css";
import { suggestionList } from "../../helper/assist/suggest";
import { Scrollbars } from "react-custom-scrollbars";
import { isOnMobile } from "../../helper/BrowserHelper";
import { updateAssistStatus } from "../../redux/chatbotSlice";

type Props = {
  inputValue: string;
  newSearch: string;
  submit: () => void;
};

const Assist: React.FC<Props> = ({ inputValue, newSearch, submit }: Props) => {
  const dispatch = useAppDispatch();
  const assistScrollBarRef = useRef<Scrollbars | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [suggestListAll, setSuggestListAll] = useState(
    suggestionList(inputValue)
  );
  const [suggestListShort, setSuggestListShort] = useState(
    suggestionList(inputValue)
  );
  const [isClickShowMore, setIsClickShowMore] = useState(false);
  const [showMoreNum, setShowMoreNum] = useState(0);
  const [previousSelections, setPreviousSelections] = useState<string[]>([]);
  const [containerHeight, setContainerHeight] = useState("140px"); // 170px

  useEffect(() => {
    // Update suggestList based on inputValue
    let suggestList = suggestionList(inputValue);

    if (suggestList.length === 0) {
      // If suggestList is empty, do nothing
      return;
    }

    const hasActions = previousSelections.length > 0;

    if (!isClickShowMore) {
      if (suggestList.length <= 3) {
        setSuggestListShort(suggestList);
        setSuggestListAll(suggestList);
        setShowMoreNum(0);
        setContainerHeight(
          (hasActions ? 50 : 30) + 30 * suggestList.length + "px"
        );
        setIsClickShowMore(false);
      } else if (suggestList.length > 3) {
        setSuggestListShort(suggestList.slice(-3));
        setSuggestListAll(suggestList);
        setShowMoreNum(suggestList.length - 3);
        setContainerHeight("140px"); // 170px
        setIsClickShowMore(false);
      }
    }
  }, [inputValue, isClickShowMore, previousSelections]);

  useEffect(() => {
    // Adjust TA height and show more number when search result updated
    let suggestList = suggestListAll;
    const hasActions = previousSelections.length > 0;

    let adjustHeight =
      suggestList.length > 3
        ? 140
        : (hasActions ? 50 : 30) + 30 * suggestList.length;

    let adjustShowMore = 0;
    if (suggestList.length <= 3) {
      adjustShowMore = 0;
    } else {
      adjustShowMore = suggestList.length - 3;
    }

    setContainerHeight(adjustHeight + "px");
    setShowMoreNum(adjustShowMore);
  }, [suggestListAll, previousSelections]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  const handleClickSuggestList = (event: React.MouseEvent<HTMLSpanElement>) => {
    // select one item in the suggest list
    let listValue = "";

    const targetElement = event.target as HTMLElement;

    if (targetElement.parentElement?.className === "suggest-text") {
      listValue = targetElement.parentElement.dataset.value || "";
    } else {
      listValue = targetElement.dataset.value || "";
    }

    if (listValue === inputValue) {
      submit();
    } else {
      dispatch(
        updateAssistStatus({ assistValue: listValue, isSelectAssist: true })
      );
      setPreviousSelections([...previousSelections, inputValue]);
      setSuggestListShort(suggestListAll);
      setIsClickShowMore(false);
    }
  };

  const handleClickBack = () => {
    // click on back button
    if (previousSelections.length >= 1) {
      let prevSelect = previousSelections.slice(-1);
      dispatch(updateAssistStatus({ assistValue: prevSelect[0] }));
      let prevSelections = previousSelections.slice(
        0,
        previousSelections.length - 1
      );
      setPreviousSelections(prevSelections);
      setIsClickShowMore(false);
    }

    if (previousSelections.length === 1) {
      dispatch(updateAssistStatus({ isSelectAssist: false }));
    }
  };

  const handleShowMore = () => {
    // click on more button
    const listLength = suggestListAll.length;
    const hasActions = previousSelections.length > 0 && showMoreNum > 1;
    setSuggestListShort(suggestListAll);
    setIsClickShowMore(true);
    setShowMoreNum(0);
    setContainerHeight(
      listLength >= 10
        ? "320px"
        : listLength * 28 + (hasActions ? 50 : 28) + "px"
    );
    if (isOnMobile()) {
      setTimeout(() => {
        const chatInput = document.querySelector(
          ".chat-input"
        ) as HTMLInputElement;
        if (chatInput) {
          chatInput.blur();
        }
      }, 100);
    }

    setTimeout(() => {
      if (assistScrollBarRef.current) {
        assistScrollBarRef.current.scrollToBottom();
      }
    }, 100);
  };

  const highlightKeyWords = (listitem: string) => {
    inputValue = inputValue.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
    const keywords = inputValue.split(/\s/);
    let pattern = null;
    pattern = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");
    return listitem.replace(pattern, (match) => `<strong>${match}</strong>`);
  };

  const renderSuggestList = () => {
    return (
      <ul className="suggest-list">
        {suggestListShort.map((listitem: string[], index: number) => {
          return (
            <li key={`suggest-${index}`} className="list-item">
              <span
                className="suggest-text"
                onClick={(e) => handleClickSuggestList(e)}
                data-value={listitem[0]}
                dangerouslySetInnerHTML={{
                  __html: highlightKeyWords(listitem[0]),
                }}
              />
              <svg className="icon icon--extra-small chevron__icon chevron__icon--grey">
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="#icon-chevron-right"
                />
              </svg>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderActions = () => {
    return (
      <p className="assist-actions" key="assist-actions">
        <span className="assist-back" onClick={handleClickBack}>
          {previousSelections.length > 0 ? "Back" : ""}
        </span>
        <span className="assist-show-more" onClick={handleShowMore}>
          {showMoreNum > 1 ? `${showMoreNum} more` : ""}
        </span>
      </p>
    );
  };

  return (
    <div className="chat-assist" style={{ height: containerHeight }}>
      {renderActions()}
      <Scrollbars
        key="assist-scroll-bar"
        className="assist-scroll-bar"
        style={
          previousSelections.length > 0 || showMoreNum > 1
            ? { height: "90%" }
            : { height: "100%" }
        }
        renderTrackHorizontal={(props) => (
          <div {...props} className="horizontal-scrollbar" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="vertical-scrollbar" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="vertical-scrollbar-inner" />
        )}
        renderView={(props) => <div {...props} className="scroll-view" />}
        ref={assistScrollBarRef}
      >
        <div className="assist-content-container">{renderSuggestList()}</div>
      </Scrollbars>
    </div>
  );
};

export default Assist;
