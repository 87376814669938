import React from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import "./chat-tray.css";
import toolConfig from "../../config/toolConfig";
import ListItem from "./ChatTrayContentListItem";
import { updateTrayExpandListIndex } from "../../redux/chatbotSlice";

const ChatTrayContentList: React.FC = () => {
  const { trayExpandListIndex } = useAppSelector((state) => state.chatbot);
  const dispatch = useAppDispatch();
  const onExpand = (index: number) => {
    dispatch(updateTrayExpandListIndex(index));
  };

  const contentData: any = toolConfig.tray.contentlist;

  return (
    <div className="content-list">
      {Object.keys(contentData).map((key, index) => {
        const { title } = contentData[key] as any;
        return (
          <ListItem
            key={title}
            data={contentData[key]}
            expandIndex={trayExpandListIndex}
            currentIndex={index}
            onExpand={onExpand}
          />
        );
      })}
    </div>
  );
};

export default ChatTrayContentList;
