import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import _ from "lodash";
import axios from "axios";
import {
  clearInputValue,
  updateAssistStatus,
  connectToLex,
  showUrgentInfo,
  updateUserMessage,
} from "../redux/chatbotSlice";

import { maskCreditCardNumbers } from "../helper/optimizeTools";
import { setUserTyping, sendLexMessage } from "../services/socketClient";
import { suggestionList } from "../helper/assist/suggest";
import toolConfig from "../config/toolConfig";
import Assist from "./chat-assist/ChatAssist";

const ChatBotFooter: React.FC = () => {
  const {
    searchInputHint,
    isInputDisable,
    isClearInputValue,
    isChatTrayOpen,
    isSelectAssist,
    isConnectToAgent,
    isFirstAssist,
    isHideAssist,
    isShowUrgentInfo,
    assistValue,
    awsConnect,
  } = useAppSelector((state) => state.chatbot);
  const [query, setQuery] = useState("");
  const [inputLength, setInputLength] = useState(0);
  const [queryAssist, setQueryAssist] = useState("");
  const [newAssistSearch, setNewAssistSearch] = useState("");
  const [urgentMessage, setUrgentMessage] = useState("");

  const userInputRef = useRef<HTMLTextAreaElement | null>(null);
  const userInputLengthRef = useRef<HTMLDivElement | null>(null);
  const changeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const dispatch = useAppDispatch();

  const handleSubmitAction = () => {
    if (!query.trim()) return;
    const message = maskCreditCardNumbers(query);

    if (!connectToLex) {
      dispatch(updateUserMessage(message));
    }
    sendLexMessage("user", message, true, isSelectAssist ? "assist" : "");

    dispatch(clearInputValue(true));
    setQuery("");
    setInputLength(0);
    setNewAssistSearch("");
    dispatch(showUrgentInfo(false));

    if (userInputLengthRef.current) {
      userInputLengthRef.current.style.color = "#00a45f";
    }
    if (userInputRef.current) {
      userInputRef.current.value = "";
    }

    onChangeHandle();
    if (!isConnectToAgent) {
      let debounceInputBlur = _.debounce(() => {
        if (userInputRef.current) {
          userInputRef.current.blur();
        }
      }, 100);
      debounceInputBlur();
    }

    dispatch(updateAssistStatus({ assistValue: "", isFirstAssist: false }));
  };

  const onKeydownHandle = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { key, currentTarget } = event;

    if (isSelectAssist === true) {
      dispatch(updateAssistStatus({ isSelectAssist: false }));
    }

    let hasValue = !!currentTarget.value;
    if (key === "Enter") {
      event.preventDefault();
      if (hasValue) {
        handleSubmitAction();
      }
    }
  };

  const updateAssistStatusFooter = useCallback(() => {
    if (userInputRef.current) {
      let list = suggestionList(userInputRef.current.value);

      if (list.length > 0) {
        dispatch(updateAssistStatus({ isHideAssist: false }));
        setNewAssistSearch(list.slice(-1)[0][0]);
      }

      if (userInputRef.current.value === "") {
        dispatch(updateAssistStatus({ isHideAssist: true }));
      }
    }
  }, [dispatch]);

  const onChangeHandle = useCallback(
    (event?: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (userInputRef.current && userInputLengthRef.current) {
        if (isClearInputValue) {
          setQuery("");
          dispatch(clearInputValue(false));
        }
        setQuery(userInputRef.current.value);

        let inputLengthNow = userInputRef.current.value.length;
        inputLengthNow >= 135
          ? (userInputLengthRef.current.style.color = "#e60000")
          : (userInputLengthRef.current.style.color = "#00a45f");
        // For Android specific only
        if (inputLengthNow > 150) {
          setQuery(userInputRef.current.value.length.toString());
          event?.preventDefault();
          return false;
        }
        // For Android specific only -END
        setInputLength(inputLengthNow);

        changeTextareaHeight();

        // Async update TA
        if (changeTimeoutRef.current) {
          clearTimeout(changeTimeoutRef.current);
        }
        changeTimeoutRef.current = setTimeout(() => {
          if (userInputRef.current) {
            setQueryAssist(userInputRef.current.value);
          }
          updateAssistStatusFooter();
        }, 300);
      }
    },
    [dispatch, isClearInputValue, updateAssistStatusFooter]
  );

  const changeTextareaHeight = () => {
    if (userInputRef.current && userInputLengthRef.current) {
      const lineHeight = 20;
      const defaultTextareaHeight = 32;
      const chatBody = document.querySelector(".chat-body") as HTMLElement;
      const chatFooter = document.querySelector(".chat-footer") as HTMLElement;
      const chatBodyinApp = document.querySelector(
        ".header-inapp .chat-body"
      ) as HTMLElement;
      let height = userInputRef.current.offsetHeight;

      if (userInputRef.current.scrollHeight > height) {
        userInputRef.current.style.height = `${
          userInputRef.current.scrollHeight + 4
        }px`;
      } else {
        while (height >= userInputRef.current.scrollHeight) {
          userInputRef.current.style.height = `${height - lineHeight}px`;
          height -= lineHeight;
        }
        userInputRef.current.style.height = `${height + lineHeight}px`;
      }
      if (chatBody && chatFooter) {
        if (userInputRef.current.scrollHeight < 50) {
          chatBody.style.height = `calc(100% - 70px - 99px - ${
            parseInt(userInputRef.current.style.height) - defaultTextareaHeight
          }px)`;
          chatFooter.style.height = `calc(${
            parseInt(userInputRef.current.style.height) -
            defaultTextareaHeight +
            100
          }px)`;
        } else {
          chatBody.style.height = `calc(100% - 70px - 79px - ${
            parseInt(userInputRef.current.style.height) - defaultTextareaHeight
          }px)`;
          chatFooter.style.height = `calc(${
            parseInt(userInputRef.current.style.height) -
            defaultTextareaHeight +
            80
          }px)`;
        }
      }

      if (chatBodyinApp) {
        chatBodyinApp.style.height = `calc(100% - 70px - 59px - ${
          parseInt(userInputRef.current.style.height) - defaultTextareaHeight
        }px)`;
      }
    }
  };

  const renderAssist = () => {
    if (toolConfig.assist.switch === "off") {
      return false;
    }

    if (isFirstAssist && !isHideAssist) {
      return (
        <Assist
          inputValue={queryAssist}
          newSearch={newAssistSearch}
          submit={handleSubmitAction}
        />
      );
    }
  };

  const renderSubmitButton = () => {
    setUserTyping(!!query);

    return (
      <div className="chat-buttons-right" onClick={handleSubmitAction}>
        <button
          className="chat-submit-button"
          disabled={!query || isClearInputValue}
        >
          Send
        </button>
      </div>
    );
  };

  const renderUrgentInformation = () => {
    if (urgentMessage && isShowUrgentInfo) {
      return (
        <p className="urgentInfoMarquee">
          <span
            dangerouslySetInnerHTML={{
              __html: urgentMessage,
            }}
          />
        </p>
      );
    }
  };

  useEffect(() => {
    if (assistValue && query !== assistValue && userInputRef.current) {
      setQueryAssist(assistValue);
      dispatch(updateAssistStatus({ assistValue: "" }));

      userInputRef.current.value = assistValue;
      onChangeHandle();
    }
  }, [assistValue, dispatch, onChangeHandle, query]);

  const getUrgentMessage = useCallback(() => {
    let message = "";
    axios
      .get(
        `${awsConnect.adminApiUrl}/platform/messages-public/chatbot/key/bannerMessage`
      )
      .then((response) => {
        message = response.data.message_text;
        setUrgentMessage(message);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [awsConnect.adminApiUrl]);

  useEffect(() => {
    getUrgentMessage();
  }, [getUrgentMessage]);

  return (
    <div className="chat-footer">
      {renderAssist()}
      {renderUrgentInformation()}
      <textarea
        key="chat-input"
        maxLength={150}
        value={isClearInputValue ? "" : query}
        disabled={isInputDisable}
        className={`chat-input${query ? " touched" : ""}`}
        placeholder={searchInputHint}
        onChange={(e) => onChangeHandle(e)}
        onKeyDown={(e) => onKeydownHandle(e)}
        ref={userInputRef}
      />
      {renderSubmitButton()}
      <div
        key="chat-input-length"
        className="input-length"
        ref={userInputLengthRef}
      >
        {inputLength}/150
      </div>
      <div
        className={`chat-footer-bottom ${
          isChatTrayOpen ? "chat-tray-open-footer" : ""
        }`}
      />
    </div>
  );
};

export default ChatBotFooter;
