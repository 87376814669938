// jshint esversion: 6
/* eslint no-unused-vars: "off" */
const config = {
  verson: "2023-0331-1540",
  texts: [
    // Items should be in order of most specific, not necessarily alphabetical
    // e.g., buy iPhone, Buy Mobile Phone, Buy Mobile Device
    "Internet keeps dropping out",
    "Account holder has passed away",
    "Account shows incorrect Add-on",
    "Activate 5G Broadband",
    "Activate my sim card",
    "Add a Static IP address",
    "Add an authorised person to my account",
    "Add another connection to my One NZ app",
    "Add another person to my account",
    "Add credit card information",
    "Add PC protection",
    "Add roaming to a different number",
    "Are endless data plans 5g enabled",
    "Are the endless data plans able to be shared",
    "Are there any services I can't use with Wireless Broadband",
    "Automatic top up for Prepay",
    "Bestmate on a different network",
    "Block on my phone is not working",
    "Block someone from messaging me",
    "Bring my number to One NZ",
    "Broadband data not working",
    "Broadband isn't working",
    "Buy 5G broadband",
    "Buy a Business mobile plan",
    "Buy a mobile phone",
    "Buy a new phone",
    "Buy a new SIM",
    "Buy a One NZ Pass",
    "Buy a Samsung Galaxy mobile phone",
    "Buy a Samsung mobile",
    "Buy a Samsung tablet",
    "Buy a tablet",
    "Buy add-ons",
    "Buy an iPad",
    "Buy an iPhone",
    "Buy Bestmate",
    "Buy Business broadband",
    "Buy extra data",
    "Buy extra minutes",
    "Buy or change my broadband plan",
    "Buy or change my Pay monthly mobile plan",
    "Buy or change my Prepay plan",
    "Buy the Prepay International plan",
    "Buy the Tablet Share plan",
    "Buy wireless broadband",
    "Calling overseas",
    "Can I get 5G Broadband",
    "Can I get 5G on Endless data?",
    "Can I get Super WIFI on 5G Broadband",
    "Can I get the new Endless plans?",
    "Can I resign to get a better deal?",
    "Can I share endless data",
    "Can I use Wifi calling on my smartphone",
    "can you still have a sharer on endless data plan?",
    "Cancel account",
    "Cancel an order",
    "Cancel broadband plan",
    "Cancel mobile broadband add-ons",
    "Cancel my account and refund remaining balance",
    "Cancel my One NZ Pass",
    "Cancel my order",
    "Cancel my services",
    "Cancel Netflix",
    "Cancel PC Protection",
    "Cancel Prepay plan",
    "Cancel SKY TV channel",
    "Cancel SKY TV subscription",
    "Cannot make or receive calls",
    "Cannot send or receive text messages",
    "Can't call voicemail",
    "Can't connect to internet at home",
    "Can't make calls out",
    "Can't make or receive calls",
    "Can't send text messages",
    "Change account holder",
    "Change account ownership",
    "Change call divert settings",
    "Change calling settings",
    "Change from Pay Monthly to Prepay",
    "Change from Prepay to Pay Monthly",
    "Change how I receive my bill",
    "Change moving house date",
    "Change my address",
    "Change my Bestmate",
    "Change my contact info",
    "Change my home phone plan",
    "Change my number",
    "Change my PIN",
    "Change my plan but keep my number",
    "Change my Prepay plan",
    "Change my voicemail greeting",
    "Change my voicemail PIN",
    "Change SIM cards",
    "Change SKY channels or package",
    "Change the name on my account",
    "Change the number that appears on my One NZ app",
    "Change the primary number",
    "Change time on my phone",
    "Change to MyFlex Prepay",
    "Change to Prepay",
    "Change to Prepay Pay & Go",
    "Chat with a live agent",
    "Chat with a real person",
    "Check for network outage in my area",
    "Check my payments made to One NZ",
    "Check my voicemail",
    "Check network status",
    "Close an account",
    "Compensation for loss of service",
    "Concert tickets",
    "Contact a chorus technician",
    "Contact One NZ media team",
    "Damaged a phone that I am paying off",
    "Data not working",
    "DataUp available for existing customers",
    "Delivery information on my order",
    "Detailed break down of my usage",
    "Difference between 4G and 5G Broadband",
    "Discuss charges on my bill",
    "Discuss early termination charges",
    "Do I need data to use a One NZ Pass",
    "Enable data roaming",
    "Enable roaming",
    "Explain my bill",
    "Explain my usage charges",
    "Explain open term",
    "Find a better deal for my Broadband",
    "Flex my plan",
    "Forgot Wi-Fi password",
    "Found a better offer",
    "Get a better deal for my Broadband",
    "Get a better deal for my phone plan",
    "Get a Companion Plan",
    "Get a copy of my receipt",
    "Get a Travel SIM card",
    "Get an IOU",
    "Get an update on my order",
    "Get DataUp",
    "Get Netflix",
    "Get status of order",
    "Get Wi-Fi Password",
    "Getting slow internet speeds",
    "Getting weak Wi-Fi signal",
    "Have not received my modem",
    "Having issues with the My One NZ App",
    "Having problems with my Voicemail",
    "Help activating a Prepay phone",
    "Help paying my bill",
    "Help setting up a new Wi-Fi connection",
    "Help setting up my modem",
    "Help topping up my phone",
    "Help using Sky TV",
    "Help with IOU",
    "Help with my bill",
    "Help with My One NZ App",
    "help with wireless broadband",
    "Home phone and VoIP",
    "How can I get a refund",
    "How do Companion Plan work?",
    "How do I bring my number to One NZ",
    "How do I enable Wifi calling on my device?",
    "How do I fix a broken phone",
    "How do I know when I exceed my max speed data allowance?",
    "How do I pay back an IOU?",
    "How do I pay my bill?",
    "How do I reactivate my SIM card",
    "How does paying by TXT work?",
    "How fast is my broadband speed",
    "How much are installation charges?",
    "How much are roaming charges",
    "How much does it cost to call my Voicemail",
    "How much is an IOU",
    "How much is Roaming?",
    "How to call overseas numbers",
    "How to check network status",
    "How to make a payment on my account",
    "How to set up my 5G Broadband",
    "How to stop One NZ no credit text messages",
    "How to stop scam calls",
    "How to top up a Prepay phone",
    "How to top up online",
    "How to top-up prepay",
    "how will i know if i run out of max speed",
    "I am behind on my bill",
    "I am receiving scam calls",
    "I can't download the My One NZ App",
    "I can't see my Prepay deals",
    "I didn't get my IOU credit",
    "I have a problem with rural internet",
    "I have been billed twice",
    "I have been charged incorrectly",
    "I have been overcharged on my bill",
    "I have lost my phone",
    "I lost my phone but want to keep my number",
    "I need a GST receipt for Prepay topup",
    "I need a new SIM card",
    "I need a PDF of my bill",
    "I need Always Connected",
    "I need help setting up call divert",
    "I need help with my One NZ Pass",
    "I need help with my online order",
    "I need to update my Direct debit",
    "I want to buy or change a plan",
    "I want to change my plan but its not showing on the app",
    "I want to share my data on endless plan",
    "I want to use my own modem",
    "I want to view my bill",
    "I would just like to check my order status",
    "If I topped up the wrong number what do I do",
    "IFP interest free charges",
    "I'm in collections",
    "Incoming calls going straight to Voicemail",
    "Incorrect charges on my bill",
    "Is 5G in my area",
    "Is 5g part of new endless data plans",
    "Is my phone insured with One NZ",
    "Is there a cost to call my Voicemail?",
    "Issue with my Sky Go app",
    "Issues bringing my number to One NZ",
    "Issues with my internet",
    "Issues with My One NZ",
    "Issues with my Rural phone",
    "Issues with my SIM card",
    "I've found a better deal, can you beat it",
    "Join One NZ",
    "Join One NZ medical dependency register",
    "Just joined, need to setup my account",
    "Keep getting a message saying 'no credit'",
    "Landline is disconnected",
    "Latest Prepay Plans",
    "Latest sales and promotions",
    "Latest special offers & promotions",
    "Manage data limits of sharers",
    "Manage my bestmate addon",
    "Missing a discount on my bill",
    "Mobile has network lock",
    "Mobile network outage in my area",
    "Mobile phone features and settings",
    "Modem issue",
    "Modem password is wrong",
    "More time to pay my bill",
    "Move House",
    "Moved my number to One NZ but its not working yet",
    "Moving address",
    "Music Pass not working",
    "My account has been disconnected for late payment",
    "My account has the wrong add ons",
    "My account is missing a payment",
    "My account is showing incorrect add-ons",
    "My account is showing the wrong broadband bundle",
    "My account is showing the wrong plan",
    "My Auto Top Up is not working",
    "My Bestmate changed provider",
    "My bill is showing the wrong roaming charges",
    "My internet is not working",
    "My invoice shows the wrong plan",
    "My IOU credit is not showing",
    "My landline has been disconnected",
    "My One NZ App is not working",
    "My One NZ app password",
    "My One NZ top up voucher is not working",
    "My payment isn't showing",
    "my phone is not working",
    "My phone's been stolen",
    "My plan is on hold",
    "My service has been barred or restricted",
    "My services have been disconnected due to bill",
    "My top up didn't work",
    "My top-up failed",
    "My top-up voucher isn't working",
    "My Voicemail is not working",
    "Need help setting up Wi-Fi",
    "Need help using SKY",
    "Need help with my Red Share connection",
    "Need to know more about DataUp",
    "Network issues on my phone",
    "Network status",
    "New internet connection is not working",
    "No connection or dial tone on my phone",
    "No dial tone on landline",
    "No internet connection",
    "No network on my mobile",
    "No signal on my phone",
    "Not enough credit to send a TXT",
    "One NZ contact phone number",
    "One NZ Rewards",
    "One NZ Stores",
    "Outages in my area",
    "Pass not working",
    "Pay with credit card",
    "Plan didn't renew after top up",
    "Plan not showing in the My One NZ App",
    "Porting Problem",
    "Prepay roaming rates",
    "Problem bringing my number to One NZ",
    "Problem setting up Wi-Fi",
    "Problem with My One NZ App",
    "Problem with topping up Prepay phone",
    "Problem with wireless broadband device",
    "Problem with wireless broadband modem",
    "Problems logging in to My One NZ",
    "Problems with calling on my landline phone",
    "Problems with my phone software update",
    "Problems with Sky Go app",
    "Put a block on a stolen phone",
    "Put my number on hold",
    "Refund a credit on my account",
    "Register a Medical Dependency",
    "Remove a credit card from my account",
    "Remove an add-on",
    "Remove an authorised person from my account",
    "Remove my number from the My One NZ App",
    "Remove PC Protection anti-virus software",
    "Remove static IP address",
    "Remove voicemail",
    "Remove voicemail from my landline",
    "Remove voicemail greeting",
    "Renew my One NZ Pass",
    "Renew my Prepay plan",
    "Renew roaming plan",
    "Renew the travel plan",
    "Report a medical emergency",
    "Report an outage",
    "Report nuisance calls or texts",
    "Report spam or nuisance calls",
    "Report stolen phone",
    "Request an extension on my bill due date",
    "Reset my One NZ app password",
    "Reset my Voicemail PIN",
    "Revert time on phone back to NZ time",
    "Roaming in Australia",
    "Roaming in the Cook Islands",
    "Roaming is not working",
    "Rural coverage",
    "See available Prepay Plans",
    "Service is too expensive",
    "Service not yet working",
    "Set up a payment",
    "Set up a payment arrangement",
    "Set up call forwarding",
    "Set up credit card payment",
    "Set up email forwarding",
    "Set up My One NZ app",
    "Set up my Voicemail",
    "Set up WIFI",
    "Set up Wireless Broadband Home Phone",
    "Settings to use for send / receive PXT messages",
    "SIM card not working",
    "Slow internet speed",
    "Social Pass not working",
    "Software not updating on phone",
    "Something is wrong with my mobile phone",
    "Speed test",
    "Stop account reminders",
    "Stop sending me invoices for closed account",
    "Store locator",
    "Sure signal not working",
    "Talk with a real person",
    "Term of my contract",
    "Top up another number",
    "Top up Prepay with credit card",
    "Topped up someone else's number",
    "Transfer credit from one account to another",
    "Tried to move my number to One NZ but it didn't work",
    "Turn off roaming",
    "Unsubscribe from marketing TXTs",
    "Update my account details",
    "Update my credit card details",
    "Update my email address",
    "Update on existing move house request",
    "Update on online order",
    "Video Pass not working",
    "View data usage",
    "View my call history",
    "View my incoming call history",
    "View my usage",
    "Waiting for my refund",
    "What are installation charges?",
    "What are Interest Free Payment (IFP) charges?",
    "What are premium charges",
    "What are pro-rated charges",
    "What are these TXT-a-Park charges on my bill",
    "What are your latest phone plans?",
    "What do I need to understand when joining a Companion Plan?",
    "what do you mean by max speed",
    "What does Pro-rated charges mean?",
    "What happened to my data",
    "What happens to my DataUp if I flex my plan",
    "What if I exceed my data allowance",
    "What is 5G broadband",
    "What is a Chat Pass?",
    "What is a late payment fee?",
    "What is a Music Pass?",
    "What is a Social Pass?",
    "What is a Video Pass?",
    "What is Companion Plan?",
    "What is Daily Roaming",
    "What is DataUp",
    "what is max speed on endless data?",
    "What is my account balance?",
    "What is my account number?",
    "What is my balance?",
    "What is my number?",
    "What is my PUK number?",
    "What is my SIM card number",
    "What is my Wi-Fi password?",
    "What is One NZ's network coverage?",
    "What is the IOU service fee?",
    "What is the PIN number for my phone",
    "What is the Prepay top up minimum amount",
    "What is Wifi calling",
    "What is Wireless Broadband?",
    "What mobile network coverage is in my area?",
    "What plan am I on",
    "What plans can I get companion plans on?",
    "What special offers do you have",
    "What's using my data",
    "When is my bill due?",
    "When will my payment show in my account",
    "When will new Internet connection be activated",
    "Where are my discounts?",
    "Where can I see my bill",
    "Where did my credit go?",
    "Where did my data go",
    "Where did my top up money go?",
    "Where do I find my phone settings",
    "Where is my account credit going?",
    "Where is the credit I was promised",
    "Where should I get my phone repaired?",
    "Where's my modem?",
    "Who can make changes on Companion Plans?",
    "Who is eligible for DataUp",
    "Why can't I get an IOU?",
    "Why cant I play videos on my phone?",
    "Why do I have a late payment fee on my bill",
    "Why do I have late payment fee",
    "Why have I been charged a late payment fee",
    "Why have I been charged early termination charges",
    "Wi-Fi not working",
    "Will my mobile phone work on One NZ's network?",
  ],
  alt_phrases: [
    // These are sequences where the phrase should be treated as a single word,
    // generally because the combination changes the meaning of one or more words.
    // Watch for precedence. First match wins - where two phrase have overlapping words.
    // also roots of words e.g., bills? or bill\w*
    // Includes items that have punctuation, e.g., ' - or .
    //
    "(can't|cannot)", // note: using regex means synonyms may not be matched because the regex is substituted for the phrase before checking for synonyms
    "(don't|do not)",
    "(didn't|did not|didnt)",
    "(doesn't|does not|doesnt)",
    "(won't|will not|wont)",
    "how do i",
    "how to",
    "how much",
    "(what is|what's|whats)",
    "I want to", // prevents the "I want" phrase which matches with buy
    "I want",
    "want to", // to match with wanna
    "top(ing|ped)?[ -]?up",
    "direct debit",
    "can you help me",
    "help with",
    "help me with",
    "need help",
    "how can i",
    "how can",
    "where can",
    "can you",
    "can I",
    // "change my"
    // "change to"
    // "problems? with"
    "contact info(rmation)?", // synonyms should use a similar pattern
    "pre pay",
    "phone number",
    "mobile phone", // note mobile phone number => phone number due to precedence
    "home phone",
    "(ive|I've|I have)", // multiple words and synonyms
    "(I'd|I would)",
    "set up",
    "pro-rated",
    "static ip address ", // address get's pulled from text so not matched as "address"
    "static ip ", // add so that cancel/add will show these with just cancel entered
    "ip address",
    "pc protect",
    "voice messages?",
    "text messages?",
    "anti virus",
    "anti-virus",
    "in-flight",
    "in flight",
    "error code",
    "leave vodafone",
    "chat with", // for syn with live chat
    "chat pass", // as opposed to chat with or live chat
    "music pass",
    "social pass",
    "video pass", // avoid video problems
    "add on",
    "add ons?",
    "like to",
    "setting up",
    "i.o.u.",
    "turn on",
    "turn off",
    "where do i",
    "where do",
    "(where is|where's|wheres|where are)",
    "(it's not|it isn't|it is not|it isnt|its not)",
    "(is not|isnt|isn't)",
    "my vodafone app",
    "My Vodafone",
    "where did",
    "vodafone tv",
    "sky tv",
    "dial tone",
    "red share",
    "(I am|I'm|Im)",
    "a/c",
    "vodafone'?s",
    "my flex",
    "call-back",
    "live chat",
    "on[ -]hold",
    "over ?charged",
    "passed away", // added 20-12-15
    "my one nz app",
    "My one nz",
    "One NZ",
    "One New Zealand",
  ],
  synonyms: [
    // IMPORTANT: multiple-word strings are phrases and must appear in the
    // phrase list to be processed.
    // For Asymetrical synonyms, [0] is customer wording, [1] is internal
    // wording so only needs to match the text above.
    // The list needs pretty much all the verbs and their conjugations and nouns
    // with their plurals
    // Order from most specific to least specific
    // e.g, "IP Address" before "Address"
    // There are two syntaxes:
    // 1. All words are interchangable
    // [word/phrase, word/phrase{, word/phrase}...]
    //
    // 2. Any of the first set translate to the latter set, but not vice-versa.
    // [[word/phrase{, word/phrase}...],[word/phrase{, word/phrase}...]]
    //
    // [[apple, banana], [fruit]] - apples and bananas are fruit but not all
    // fruit is an apple or a banana.
    // apple -> apple, fruit
    //
    // [[device],[phone, modem]] - any reference to a device may refer to a
    // phone or modem, but a reference to a modem does not refer to a device
    ["prepay", "prepaid", "pre pay"],
    [
      "top up",
      "topup",
      "topping",
      "topped",
      "topping up",
      "top(ing|ped)?[ -]?up",
    ],
    ["can't", "cannot", "cant"],
    ["addon", "addons", "add-on", "add-ons", "add on", "add ons"],
    // don't
    // do not
    // didn't
    // did not
    ["info", "information"],
    ["cancel", "remove", "delete", "disconnect"],
    // up/down grade => change
    [
      ["upgrade", "downgrade"],
      ["buy", "change", "upgrade", "downgrade", "purchase"],
    ],
    ["buy(ing)?", "upgrad(e|ing)", "downgrad(e|ing)", "purchas(e|ing)"],
    ["I want", "buy"], // "I want" will show buy items, but not "I want to" (it is a higher priority phrase). When they get to "I want to buy", "buy" will take precedence
    ["chang(e|ing)", "switch(ing)?", "swap"],
    ["receiv\\w*", "deliver(\\w*)"], // received, receiving, receives...
    ["discounts?"],
    ["bar", "barred", "restricted"],
    ["incorrect", "wrong"],

    ["static ip address", "static ip"], // must be above "address" synonym

    // ["contact info","contact information"],
    // ["email","phone number", "contact info", "contact information"]
    // Customer may put phone number, or ph. number or ph number
    [["email", "ph(one|\\.)? number", "address"], ["contact info(\\w+)?"]],
    [["contact info(\\w+)?"], ["email", "phone number", "address"]],
    // consider - phone is a device, device could be a phone.
    // home phone is a landline, not a device.
    // so
    // home phone <=> landline
    // device => phone
    // phone /=> device
    ["landline", "home phone"], // get first
    // if device is used, look for phone but not home phone or phone number
    [["device"], ["(?!home )phone(?! number)", "modem", "tv"]],
    [
      ["mobile phone"],
      ["iphone", "S\\d", "Samsung mobile", "Galaxy", "device"],
    ],
    ["sky ?tv"],
    ["vodafone ?tv"],
    [["modem", "tv"], ["device"]],
    // specific devices
    [
      ["iphone", "S\\d+"],
      ["device", "mobile phone", "mobile"],
    ],
    [["ipad"], ["device", "tablet", "mobile"]],

    // leave -> cancel and leave vodafone -> cancel service
    ["leave Vodafone", "cancel service"],
    [["leave"], ["cancel"]],

    ["internet", "broadband"],
    ["text(s|ing)?", "txt(s|ing)?", "msg(s|ing)?", "messages?"],
    ["anti-virus", "anti virus", "antivirus", "pc protect"],

    ["set up", "setup", "setting up"],
    ["settings?"],
    ["problems?", "issues?"],
    ["can you help me", "need help", "help with", "help me with"],
    ["need", "get", "want", "like to"],
    ["in flight", "in-flight", "inflight"],
    ["availab(le|ility)"],
    ["pay\\w*"], // pay, paying, payment
    ["bill\\w*"], // bill or bills or billing
    ["roam\\w*"], // roam roaming, roamed ...
    ["channels?"],
    ["authoris\\w+"], // authoris ed ing
    ["activat\\w+"], // activate and activating
    ["contacts?"],
    ["plans?"],
    ["fees?", "charge\\w*"],
    ["rates?"],
    ["discounts?"],
    ["call(s|ing|ed|er)?"], // calls, called, calling
    ["mov(e|ing|ed)"],
    ["freez(e|es|ing)"],
    ["cards?"],
    ["bring(ing)?"],
    ["perform(ing?)", "work(ing|s)?"],
    ["us(e|ing)"],
    ["wi-fi", "wifi"],
    ["send(ing)?"],
    ["show(s|ing)?", "display(s|ing)?"],
    ["speeds?"],
    ["how much", "cost", "price", "charges?"],
    ["repair(s|ed|ing)?", "fix(es|ed)?"],
    ["pass(es)?"],
    ["chat with", "talk", "speak", "live chat"],
    ["agent", "person", "human", "live chat"],
    [["phone"], ["mobile phone"]],
    ["forward(ing)?"],
    ["want to", "wanna"],
    ["house", "home"],
    ["set(ing)?"],
    ["i.o.u.", "IOU"],
    ["enable", "turn on"],
    ["disable", "turn off"],
    ["countr(y|ies)"],
    ["where is", "where's", "where are"],
    ["lost", "find"],
    ["videos?"],
    ["dial[ -]?tone"], // dialtone, dial-tone, dial tone
    ["check", "verfiy"],
    ["amount", "amt"],
    ["account", "acct", "a/c"],
    [
      ["my vodafone", "my vodafone app", "myvodafone"],
      ["my one nz", "my one nz app"],
    ],
    ["my flex", "myflex", "flexipay", "flex"],
    ["stole", "took"],
    ["stores?"],
    ["dead", "deceased", "passed away", "died"], // dead and died might be matched with "my phone has died"
    ["overcharged", "over charged"],
    ["view", "see"],
    [["invoice"], ["bill"]],
  ],
  alt_ignore: [
    //
    //
    //  Words and phrases that are ignored. For a phrase to be ignored it must be
    //  in the phrase list
    //  The ignore list is about ignoring very common words that could give a lot
    //  of responses
    "and",
    "help",
    "how", // ignore "how" but not "how to" - treated as one word - or how much, how can
    "what", // but not what is
    "to",
    "with",
    "my",
    "do",
    "in",
    "is",
    "I",
    "and",
    "an",
    "the",
    "a",
    // prefer more detail - can I, where can, how can, can you, can someone
    "of",
    "are",
    "as",
    "just",
    "now",
    "\\d+",
  ],
  ads: [
    //  display images based on keywords.
    //  [[word or phrase{, word or phrase}...], HTML to be inserted]
    //  All words and phrases must be found in the search text.
    //   words/phrase may use REGEX patterns including negations
    //   ['mobile', '(!cancel)'] -- word mobile is used but cancel must not be used.
    //   HTML generally includes images and may include hyperlinks
  ],
};
export default config;
