import React, { useEffect } from "react";
import { useAppSelector } from "../redux/hooks";
import {initilaizeLexConnection,} from "../services/socketClient";
import ChatBotBody from './ChatBody';
import ChatHeader from "./ChatHeader";
import ChatBotFooter from './ChatFooter';
import ChatTray from './chat-tray/ChatTray';

const ChatWindow: React.FC = () => {
  const { chatMessage, showBotHeader } = useAppSelector((state) => state.chatbot);

  useEffect(() => {
    initilaizeLexConnection();
  }, []);

  return (
    <div
      className={`chat-window ${
        showBotHeader ? "" : "chat-window-inapp"
      }`}
    >
      <ChatTray />
      <div
        className={`chat-section-wrapper ${
          chatMessage.length < 1 ? "splash-screen-enable" : ""
        } ${showBotHeader ? "" : "header-inapp"}`}
      >
        <ChatHeader />
        <ChatBotBody />
        <ChatBotFooter />
      </div>
    </div>
  );
};

export default ChatWindow;
