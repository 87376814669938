const commonConstants = {
    color: {
        thumbs: '#888888',
        thumbsHover: '#4a4a4a'
    },
    utterance: {
        thumbsUp: 'Thumbs Up',
        thumbsDown: 'Thumbs Down',
        above25Mbps: 'Above 25Mbps',
        below25Mbps: 'Below 25Mbps',
        defaultQuestion: 'welcome'
    },
    livechat: {
        historyMsg: {
            firstIndexDefault: 10,
            msgGroupNum: 12
        },
        dataType: {
            event: 'EVENT'
        },
        eventType: {
            agentJoined: 'application/vnd.amazonaws.connect.event.participant.joined',
            agentLeft: 'application/vnd.amazonaws.connect.event.participant.left',
            transferSucceeded: 'application/vnd.amazonaws.connect.event.transfer.succeeded',
            chatEnded: 'application/vnd.amazonaws.connect.event.chat.ended'
        },
        participantRole: {
            agent: 'AGENT',
            system: 'SYSTEM'
        }
    }
};

export default commonConstants;
