import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type EnvType = {
  chatApiEndpoint: string;
  region: string;
  contactFlowId: string;
  instanceId: string;
  zeroRatedPoint: string;
  adminApiUrl: string;
  environment: string;
};

type StateType = {
  awsConnect: EnvType;
  chatMessage: ChatMessageType[];
  quickReply: QuickReplyType[];
  isInputDisable: boolean;
  isInChatQueue: boolean;
  isConnectToAgent: boolean;
  isClearInputValue: boolean;
  isChatTrayOpen: boolean;
  trayEmbedHtmlUrl: string;
  isRenderHtmlInTray: boolean;
  trayExpandListIndex: number;
  trayIframeLoaded: boolean;
  assistValue: string;
  isFirstAssist: boolean;
  isHideAssist: boolean;
  isSelectAssist: boolean;
  connectToLex: boolean;
  userMessage: string;
  isShowUrgentInfo: boolean;
  isChatActive: boolean;
  isBotTyping: boolean;
  searchInputHint: string;
  initQuestion: string;
  msisdn: string;
  showBotHeader: boolean;
  pageCategory: string;
};

type MessageContentType = {
  text: string;
  quick_replies: QuickReplyType;
};

export type ChatMessageType = {
  type: string;
  sender: string;
  message: MessageContentType;
  chatId: string;
  isGroupMsg: boolean;
};

export type QuickReplyType = {
  payload: string;
  title: string;
  istnps: string;
  action: string;
};

type AssistStatusType = {
  assistValue?: string;
  isSelectAssist?: boolean;
  isFirstAssist?: boolean;
  isHideAssist?: boolean;
};

type RenderHTMLInTrayType = {
  isRenderHtmlInTray?: boolean;
  trayEmbedHtmlUrl?: string;
  trayIframeLoaded?: boolean;
};

const initialState: StateType = {
  awsConnect: {
    chatApiEndpoint: "",
    region: "ap-southeast-2",
    contactFlowId: "",
    instanceId: "",
    zeroRatedPoint: "",
    adminApiUrl: "",
    environment: "local",
  },
  chatMessage: [],
  quickReply: [],
  isInputDisable: true,
  isInChatQueue: false,
  isConnectToAgent: false,
  isClearInputValue: true,
  isChatTrayOpen: false,
  trayEmbedHtmlUrl: window.location.hostname,
  isRenderHtmlInTray: false,
  trayExpandListIndex: -1,
  trayIframeLoaded: false,
  assistValue: "",
  isFirstAssist: true,
  isHideAssist: true,
  isSelectAssist: false,
  connectToLex: false,
  userMessage: "",
  isShowUrgentInfo: true,
  isChatActive: false,
  isBotTyping: false,
  searchInputHint: "",
  initQuestion: "",
  msisdn: "",
  showBotHeader: true,
  pageCategory: "normal",
};

const checkIsGroupMsg = (
  existMsg: ChatMessageType[],
  newMsg: ChatMessageType
) => {
  if (existMsg.length > 0) {
    let updatedChatMessage = existMsg.map((msg, index) => {
      if (index > 0) {
        if (msg.sender === existMsg[index - 1].sender) {
          return { ...msg, isGroupMsg: true };
        } else {
          return { ...msg, isGroupMsg: false };
        }
      }
      return msg;
    });

    let updatedNewMessage = newMsg;
    if (newMsg.sender === existMsg[existMsg.length - 1].sender) {
      updatedNewMessage = { ...newMsg, isGroupMsg: true };
    }
    return [...updatedChatMessage, updatedNewMessage];
  }
  return [...existMsg, newMsg];
};

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    updateEnv: (state, action: PayloadAction<EnvType>) => {
      state.awsConnect = action.payload;
    },
    handleLauncherAction: (state, action: PayloadAction<boolean>) => {
      state.isChatActive = action.payload;
    },
    reInitializeChat: (state, action: PayloadAction<any>) => {
      return {
        ...initialState,
        ...action.payload,
      };
    },
    connectToLex: (state, action: PayloadAction<boolean>) => {
      state.connectToLex = action.payload;
    },
    updateUserMessage: (state, action: PayloadAction<string>) => {
      state.userMessage = action.payload;
    },
    showUrgentInfo: (state, action: PayloadAction<boolean>) => {
      state.isShowUrgentInfo = action.payload;
    },
    botTyping: (state, action: PayloadAction<boolean>) => {
      state.isBotTyping = action.payload;
    },
    disableChatInput: (state, action: PayloadAction<boolean>) => {
      state.isInputDisable = action.payload;
    },
    dispatchChatMessage: (
      state,
      action: PayloadAction<{
        chatMessage: ChatMessageType;
        quickReply: any;
      }>
    ) => {
      const { chatMessage, quickReply } = action.payload;
      const updatedChatMessage = checkIsGroupMsg(
        state.chatMessage,
        chatMessage
      );
      state.chatMessage = updatedChatMessage;
      state.quickReply = quickReply;
    },
    liveChatQueue: (state, action: PayloadAction<boolean>) => {
      state.isInChatQueue = action.payload;
    },
    connectToAgent: (state, action: PayloadAction<boolean>) => {
      state.isConnectToAgent = action.payload;
    },
    clearInputValue: (state, action: PayloadAction<boolean>) => {
      state.isClearInputValue = action.payload;
    },
    toggleChatTray: (state, action: PayloadAction<boolean>) => {
      state.isChatTrayOpen = action.payload;
    },
    renderHtmlInTray: (state, action: PayloadAction<RenderHTMLInTrayType>) => {
      state.isRenderHtmlInTray =
        action.payload.isRenderHtmlInTray ?? state.isRenderHtmlInTray;
      state.trayEmbedHtmlUrl =
        action.payload.trayEmbedHtmlUrl ?? state.trayEmbedHtmlUrl;
      state.trayIframeLoaded =
        action.payload.trayIframeLoaded ?? state.trayIframeLoaded;
    },
    updateTrayExpandListIndex: (state, action: PayloadAction<number>) => {
      state.trayExpandListIndex = action.payload;
    },
    updateAssistStatus: (state, action: PayloadAction<AssistStatusType>) => {
      state.assistValue = action.payload.assistValue ?? state.assistValue;
      state.isSelectAssist =
        action.payload.isSelectAssist ?? state.isSelectAssist;
      state.isFirstAssist = action.payload.isFirstAssist ?? state.isFirstAssist;
      state.isHideAssist = action.payload.isHideAssist ?? state.isHideAssist;
    },
    updateSearchHint: (state, action: PayloadAction<string>) => {
      state.searchInputHint = action.payload;
    },
    updateInitQuestion: (state, action: PayloadAction<string>) => {
      state.initQuestion = action.payload;
    },
    updateMsisdn: (state, action: PayloadAction<string>) => {
      state.msisdn = action.payload;
    },
    updateShowBotHeader: (state, action: PayloadAction<boolean>) => {
      state.showBotHeader = action.payload;
    },
    updatePageCategory: (state, action: PayloadAction<string>) => {
      state.pageCategory = action.payload;
    },
  },
});

export const {
  updateEnv,
  handleLauncherAction,
  reInitializeChat,
  connectToLex,
  updateUserMessage,
  showUrgentInfo,
  botTyping,
  disableChatInput,
  dispatchChatMessage,
  liveChatQueue,
  connectToAgent,
  clearInputValue,
  toggleChatTray,
  renderHtmlInTray,
  updateTrayExpandListIndex,
  updateAssistStatus,
  updateSearchHint,
  updateInitQuestion,
  updateMsisdn,
  updateShowBotHeader,
  updatePageCategory,
} = chatbotSlice.actions;

export default chatbotSlice.reducer;
