import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useAppDispatch } from "../redux/hooks";
import queryString from "query-string";
import ReactHowler from "react-howler";
import {
  handleLauncherAction,
  updateSearchHint,
  updateInitQuestion,
  updateMsisdn,
  updateShowBotHeader,
  updatePageCategory,
} from "../redux/chatbotSlice";
import toolConfig from "../config/toolConfig";
import ChatApp from "./ChatApp";
import ChatSvgs from "./ChatSvgs";
import cmsConfig from "../config/cmsConfig";
import { isOnMobile } from "../helper/BrowserHelper";
import "./chatbot.css";

const Chatbot: React.FC = () => {
  const dispatch = useAppDispatch();
  const [playingSound, setPlayingSound] = useState(false);

  const getInitQuestion = (): string => {
    const searchString = queryString.parseUrl(window.location.search).query;
    if (searchString.q) {
      return searchString.q as string;
    } else if (searchString.initquestion) {
      return searchString.initquestion as string;
    }
    return "";
  };

  const getInputPlaceholderHint = (hints: any) => {
    return hints ? hints[Math.floor(Math.random() * hints.length)] : "";
  };

  useEffect(() => {
    const searchString: any = queryString.parseUrl(
      window.location.search
    ).query;
    setTimeout(() => {
      dispatch(
        handleLauncherAction(true)
      );
    }, 1000);
    dispatch(updateInitQuestion(getInitQuestion()));
    dispatch(updateMsisdn(searchString.ref ?? ""));

    dispatch(
      updateShowBotHeader(
        typeof searchString.botheader !== "undefined" &&
          searchString.botheader === "false"
          ? false
          : true
      )
    );
    dispatch(
      updateSearchHint(
        getInputPlaceholderHint(cmsConfig.vfoMultiSearchInputHint)
      )
    );

    const urlList = toolConfig.sales.urlList;
    const landingUrl = `${window.location.pathname}${window.location.search}`;
    if (urlList.indexOf(landingUrl) >= 0) {
      dispatch(updatePageCategory("sales"));
      if (!isOnMobile() && window.self === window.top) {
        const waitSeconds = parseInt(toolConfig.sales.autoOpenTime, 10);
        setTimeout(() => {
          dispatch(handleLauncherAction(true));
        }, waitSeconds * 1000);
      }
    } else {
      dispatch(updatePageCategory("normal"));
    }
  }, [dispatch]);

  const handlePlaySound = () => {
    setPlayingSound(true);
  };

  return (
    <Provider store={store}>
      <ChatApp />
      <ChatSvgs />
      <ReactHowler
        src={["notification.mp3"]}
        playing={playingSound}
        onEnd={() => setPlayingSound(false)}
      />
      <button
        onClick={handlePlaySound}
        id="playSoundButton"
        style={{ display: "none" }}
      >
        Play
      </button>
    </Provider>
  );
};

export default Chatbot;
