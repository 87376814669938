export const encodeResponseURL = (response: string): string => {
    let regForLinks = /href="(https?:\/\/.+?)"/g;
    if (regForLinks.test(response)) return response;
  
    let regForURL = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
    return response.replace(regForURL, url => {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  };
  
  export const maskCreditCardNumbers = (message: string): string => {
    let regForCreditCard = /((?:(?:4\d{3})|(?:5[1-5]\d{2})|6(?:011|5[0-9]{2}))(?:-?|\040?)(?:\d{4}(?:-?|\040?)){3}|(?:3[4,7]\d{2})(?:-?|\040?)\d{6}(?:-?|\040?)\d{5})/;
    return message.replace(regForCreditCard, '{Credit Card}');
  };
  