import React from "react";
import ChatWindow from "./ChatWindow";
import { CSSTransition } from "react-transition-group";
import { useAppSelector } from "../redux/hooks";

const ChatApp: React.FC = () => {
  const {isChatActive} = useAppSelector((state) => state.chatbot);
  const renderChatWindow = () => {
    return (
      <CSSTransition
        in={isChatActive}
        timeout={250}
        classNames={{
          enter: "chat-window-enter",
          enterActive: "chat-window-enter-active",
          exit: "chat-window-exit",
          exitActive: "chat-window-exit-active",
        }}
        unmountOnExit
      >
        <ChatWindow />
      </CSSTransition>
    );
  };

  return (
    <div className={`chat-bot-container`}>
      {renderChatWindow()}
    </div>
  );
};

export default ChatApp;
