import React from "react";
import Lottie from "lottie-react";
import uniqid from "uniqid";
import oneSvg from "./resource/onenz-spinner-light.json";

const constructSpinner = (animationJson: any, size: string) => {
  const height = animationJson.h;
  return (
    <div className={`lottie--${size}`} key={uniqid()}>
      <Lottie animationData={animationJson} style={{ height }} loop autoPlay />
    </div>
  );
};

const Spinner = () => {
  return (
    <div className="lottie">
      {[
        constructSpinner(oneSvg, "small"),
        constructSpinner(oneSvg, "medium"),
        constructSpinner(oneSvg, "large"),
      ]}
    </div>
  );
};

export const SmallSpinner = () => {
  return <div className="lottie">{[constructSpinner(oneSvg, "small")]}</div>;
};

export default Spinner;
