import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import ChatBotTrayHeader from "./ChatTrayHeader";
import ChatBotTrayBody from "./ChatTrayBody";
import { getWindowWidth } from "../../helper/resizeEvents";
import "./chat-tray.css";

const ChatTray: React.FC = () => {
  const { isChatTrayOpen } = useAppSelector((state) => state.chatbot);
  const [trayOpenPosition, setTrayOpenPosition] = useState(
    `-${getWindowWidth()}px`
  );

  const onWindowResize = () => {
    setTrayOpenPosition(
      `-${getWindowWidth()}px`
    );
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  });

  return (
    <div
      className={`chat-tray ${isChatTrayOpen ? "chat-tray-open" : ""}`}
      style={
        isChatTrayOpen ? { left: "0" } : { left: trayOpenPosition }
      }
    >
      <ChatBotTrayHeader />
      <ChatBotTrayBody />
    </div>
  );
};

export default ChatTray;
